exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("assets/images/pc/common/img_banner_beginner_pc.png"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("assets/images/pc/common/img_banner_beginner_middle.png"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("assets/images/sp/common/img_banner_beginner_sp.png"));

// Module
exports.push([module.id, ".how-to-use-image[data-v-b147380e]{grid-area:how-to-use-image;background:url(" + ___CSS_LOADER_URL___0___ + ") no-repeat;background-color:#0086cc;background-position-x:-2px;background-position-y:-2px;background-size:344px 626px;border-radius:4px;box-shadow:0 0 16px 0 hsla(0,0%,75.3%,.7);height:622px;transition:box-shadow .4s ease;width:340px}.how-to-use-image[data-v-b147380e]:hover{box-shadow:0 0 16px 0 rgba(0,134,204,.5)}.how-to-use-image[data-v-b147380e]:hover:active{box-shadow:0 0 2px 0 rgba(0,134,204,.5)}@media screen and (max-width:1296px){.how-to-use-image[data-v-b147380e]{background:url(" + ___CSS_LOADER_URL___1___ + ") no-repeat;background-size:cover;height:160px;width:710px}}@media screen and (max-width:920px){.how-to-use-image[data-v-b147380e]{background:url(" + ___CSS_LOADER_URL___2___ + ") no-repeat;background-size:contain;width:100%;height:60px;border-radius:0;box-shadow:none}.how-to-use-image[data-v-b147380e]:hover,.how-to-use-image[data-v-b147380e]:hover:active{box-shadow:none}}@media screen and (max-width:550px){.how-to-use-image[data-v-b147380e]{background-size:cover;height:80px}}@media screen and (max-width:414px){.how-to-use-image[data-v-b147380e]{height:60px}}", ""]);

